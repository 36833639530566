<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col my-auto">
        <h3 class="text-primary fw-bold mb-0">Support Requests</h3>
      </div>
      <div class="col-auto ms-auto my-auto">
        <router-link to="/support-requests/create" class="btn btn-sm btn-primary">
          <i class="far fa-plus me-2"></i>Open Support Request
        </router-link>
      </div>
    </div>

    <div class="card border-0 shadow-none">
      <div class="card-body">
        <ul
          class="list-group list-group-flush"
          v-if="supportRequests.total > 0"
        >
          <li
            class="list-group-item list-group-item-action"
            v-for="sr in supportRequests.issues"
            :key="sr.id"
          >
            <div class="row">
              <div class="col">
                {{ sr.fields.summary }}
              </div>

              <div class="col-auto ms-auto">
                <div class="badge " :class="sr.fields.status.id == 5 ? 'bg-success' : 'bg-secondary'">
                {{ sr.fields.status.name }}
                </div>
              </div>

              <div class="col-auto ms-auto">
                <div class="badge bg-success">
                  {{ sr.fields.created | formatDateTime }}
                </div>
              </div>
              <div class="col-auto">
                <router-link
                  :to="'/support-requests/' + sr.id"
                  class="btn btn-sm btn-outline-primary"
                  >View ticket</router-link
                >
              </div>
            </div>
          </li>
        </ul>

        <!-- <div class="row">
          <div class="col">
            <button
              class="btn btn-primary"
              v-if="showNext"
              @click="startAt = supportRequests.issues.length + 1"
            >
              Next
            </button>
          </div>
        </div> -->

        <div v-if="supportRequests.total == 0 && !loading" class="text-center">
          <div class="mb-4">
            <i class="far fa-exclamation fa-4x text-primary"></i>
          </div>
          <h5 class="fw-bold text-primary">
            No support requests found
          </h5>
        </div>

        <busy v-if="loading" s></busy>
      </div>
    </div>

    <!--  -->

    <!--  -->
  </div>
</template>

<script>
import Busy from "@/components/Busy.vue";

export default {
  data() {
    return {
      supportRequests: [],
      loading: true,
      startAt: 0,
    };
  },
  computed: {
    showNext() {
      return true;
    },
  },
  watch: {
    startAt: {
      handler: function (val, old) {
        if (val != old) {
          this.fetchSupportRequests();
        }
      },
    },
  },
  methods: {
    async fetchSupportRequests() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/support-requests?startAt=" +
          this.startAt
      );

      this.supportRequests = data;
      this.loading = false;
    },
  },
  filters: {
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
  },
  created() {
    this.fetchSupportRequests();
  },
  components: {
    Busy,
  },
};
</script>

<style>
</style>